// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-trombinoscope-js": () => import("/mnt/wsl/prod/phileog/gatsby-hacktadridf-fspf/src/templates/trombinoscope.js" /* webpackChunkName: "component---src-templates-trombinoscope-js" */),
  "component---src-templates-content-js": () => import("/mnt/wsl/prod/phileog/gatsby-hacktadridf-fspf/src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-user-js": () => import("/mnt/wsl/prod/phileog/gatsby-hacktadridf-fspf/src/templates/user.js" /* webpackChunkName: "component---src-templates-user-js" */),
  "component---src-pages-404-js": () => import("/mnt/wsl/prod/phileog/gatsby-hacktadridf-fspf/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/mnt/wsl/prod/phileog/gatsby-hacktadridf-fspf/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/mnt/wsl/prod/phileog/gatsby-hacktadridf-fspf/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

exports.data = () => import("/mnt/wsl/prod/phileog/gatsby-hacktadridf-fspf/.cache/data.json")

